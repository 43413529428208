body,
ul,
ol,
p {
	padding: 0;
	margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
	padding: 0;
	margin: 0;
}
a {
	text-decoration: none;
	color: inherit;
}

ul,
ol {
	list-style: none;
}

textarea {
	resize: vertical;
}
* {
	box-sizing: border-box;
}

body,
html {
	width: 100%;
	height: 100%;
	/* overflow: hidden; */
	font-family: "Montserrat";
}
abbr {
	text-decoration: none;
}

fieldset {
	border: none;
	padding: 0;
}
legend {
	display: none;
}

.sort-selection {
	text-align: right;
	margin-top: 10px;
	padding-right: 20px;
}

select {
	background-color: white;
	padding: 5px 10px;
	border: 1px solid #666;
	border-radius: 5px;
}
