.help-component-wrapper .help-modal .help-title {
	font-family: "Montserrat";
}
.next-img {
	height: 30px;
	width: 100%;
	background-size: contain;
	transform: rotate(180deg);
	background-repeat: no-repeat;
}
.prev-img {
	height: 30px;
	width: 100%;
	background-size: contain;
	background-repeat: no-repeat;
}
.current-slide-details-mobile {
	text-align: center;
	margin-top: -25px;
	position: relative;
}
.email-text {
	color: #6b32fa;
	white-space: nowrap;
	margin-left: 5px;
}
.slick-slider {
	background-color: white;
}

@media only screen and (min-width: 991px) {
	.current-slide-details-desktop {
		text-align: center;
		margin-top: -60px;
		position: relative;
	}
}
