.printer-img-section {
	width: 40%;
	max-width: 130px;
	height: 110px;
	display: inline-block;
	vertical-align: middle;
	background: url("../../../common/images/Printer-Icon.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-color: gray;
}
.printer-details-section {
	display: inline-block;
	width: 60%;
	vertical-align: top;
	padding-left: 13px;
	&.full-width {
		width: 100%;
	}
	.building-name {
		font-size: 16px;
		font-weight: 600;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.details-content {
		margin-top: 5px;
		font-size: 13px;
		color: #666666;
		&.address {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		.available-printers {
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			.img {
				width: 10px;
				height: 10px;
				background-size: cover;
				background-repeat: no-repeat;
			}
		}

		.left-content {
			float: left;
			cursor: pointer;
		}
		.right-content {
			float: right;
			&.blue {
				color: #6b32fa;
			}
		}
	}
}

@media only screen and (min-width: 991px) {
	.printer-img-section {
		max-width: 140px;
		height: 120px;
	}
	.printer-details-section {
		.building-name {
			font-size: 18px;
		}
		.details-content {
			font-size: 14px;
		}
	}
}
