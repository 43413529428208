$max-width: 480px;

.home-wrap {
	font-family: "Montserrat", sans-serif;
	position: relative;
	height: 100vh; //fall back
	height: calc(var(--vh, 1vh) * 100); //safari in mobile view port height fix
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
	.help-icon {
		position: absolute;
		right: 15px;
		width: 45px;
		height: 45px;
		font-size: 24px;
		box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
		background-color: #ffffff;
		text-align: center;
		line-height: 45px;
		border-radius: 50%;
		color: #8a8a8f;
		cursor: pointer;
		bottom: 250px;
		z-index: 8;
	}
	.go-to-user-location-section {
		font-weight: 600;
		width: 139px;
		height: 30px;
		border-radius: 16.5px;
		box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
		background-color: #ffffff;
		position: absolute;
		top: 90px;
		left: 15px;
		color: #747474;
		font-size: 12px;
		line-height: 30px;
		padding-left: 20px;
		z-index: 7;
		cursor: pointer;
	}
	.your-location {
		height: 16px;
		opacity: 0.6;
		width: 16px;
		position: absolute;
		background-size: contain;
		right: 15px;
		top: 7px;
		background-repeat: no-repeat;
	}
}
.content-over-map {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	padding: 30px 15px 20px 15px;
	z-index: 8;
	max-width: 100%;
	transition: 0.25s all linear;
	&.searching {
		padding-left: 0;
		padding-right: 0;
		background: white;
		height: 100%;
	}
	.search-bar-wrap {
		display: flex;
		&.searching {
			padding-left: 0;
			padding-right: 0;
			border: 1px solid #e6e6e6;
		}
	}
}
.loader-section-home {
	background: white;
	.search-loading-section-home {
		padding: 30px 15px 20px 15px;
	}
	.printers-loading-section-home {
		padding: 20px;
		padding-top: 0px;
	}
}

@media only screen and (min-width: 991px) {
	.home-wrap {
		.help-icon {
			top: 25px;
			z-index: 11;
		}
		.go-to-user-location-section {
			left: 500px;
			z-index: 10;
			top: 35px;
		}
	}
	.content-over-map {
		padding: 0;
		z-index: 10;
		width: $max-width;
		position: relative;
		.search-bar-wrap {
			padding: 20px;
			box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
			background-color: #ffffff;
		}
	}
	.loader-section-home {
		width: $max-width;
		.search-loading-section-home {
			padding: 0px 0px 10px 0px;
		}
		.printers-loading-section-home {
			padding-bottom: 20px;
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}
