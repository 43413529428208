.maps-wrap {
	font-family: "Montserrat", sans-serif;
	height: 100vh; //fall back
	height: calc(var(--vh, 1vh) * 100); //safari in mobile view port height fix
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	overflow: hidden;
	.my-location {
		width: 20px;
		height: 20px;
		background-size: contain;
		background-repeat: no-repeat;
	}
	.tool-tip {
		font-family: "Montserrat";
		background: white;
		padding: 20px;
		position: relative;
		font-size: 12px;
		z-index: 12;
		width: 250px;
		height: 120px;
		border-radius: 10px;
		box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
		top: -130px;
		left: -106px;
		.triangle {
			width: 0px;
			height: 0px;
			position: absolute;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 10px solid white;
			top: 120px;
			left: 110px;
		}
		.go-icon {
			position: absolute;
			background-size: contain;
			height: 26px;
			width: 30px;
			background-repeat: no-repeat;
			left: 50%;
			bottom: 0px;
			transform: translate(-50%);
		}
		.close {
			position: absolute;
			right: 3px;
			top: 2px;
			font-size: 10px;
			height: 13px;
			width: 13px;
			text-align: center;
			background-size: contain;
			background-repeat: no-repeat;
		}
		.name {
			font-size: 18px;
			margin-bottom: 10px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: 600;
		}
	}
	.details-content-section {
		margin-top: 5px;
		color: #747474;
		font-weight: 500;
		font-size: 16px;
		.available-printers {
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			.img {
				width: 10px;
				height: 10px;
				background-size: cover;
				background-repeat: no-repeat;
			}
		}
	}
}
.markers-wrap {
	position: relative;
	top: 0;
}
.gm-control-active {
	&.gm-fullscreen-control {
		display: none;
	}
}

@media only screen and (min-width: 991px) {
	.maps-wrap {
		width: calc(100vw - 480px);
		right: 0;
		bottom: 0;
		height: 100vh;
		left: unset;
	}
}
