.help-modal {
	width: 100%;
	min-width: 280px;
	height: 100%;
	position: relative;
	z-index: 10;
	top: 70px;
	background: white;
	padding: 20px;
	padding-bottom: 0px;
	&.printViaEmail {
		background-color: white;
	}
	.mobile-header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 70px;
		background-color: #ffffff;
		padding: 20px 15px;
		&.border {
			border: 1px solid #e6e6e6;
		}
		.back-icon-wrap {
			width: 30px;
			height: 30px;
			background-size: contain;
			background-repeat: no-repeat;
			cursor: pointer;
			display: inline-block;
			vertical-align: middle;
		}
		.header-title {
			display: inline-block;
			vertical-align: middle;
			width: 80%;
			text-align: center;
		}
	}
	.desktop-header {
		display: none;
	}
	.wrapper {
		height: 100%;
		min-height: 480px;
		text-align: center;
		height: calc(100vh - 230px);
		background-color: #ffffff;
		position: relative;
		.content {
			display: inline-block;
		}
	}
	.help-img {
		width: 350px;
		height: 246px;
		margin: 0 auto;
		background-size: cover;
		background-repeat: no-repeat;
		box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
	}
	.no-img {
		width: 0px;
		height: 150px;
		margin: 0 auto;
	}
	.help-title {
		margin-top: 50px;
		text-align: center;
	}
	.help-content {
		line-height: 1.5;
		padding: 15px;
		margin-top: 10px;
		text-align: center;
		font-size: 15px;
		color: #666666;
	}
	.next {
		position: absolute;
		right: 60px;
		bottom: 0px;
	}
	.previous {
		position: absolute;
		left: 75px;
		bottom: 0px;
	}
	.button {
		width: 75px;
		height: 35px;
		display: inline-block;
		line-height: 22px;
		text-align: center;
		margin-right: 0;
		cursor: pointer;
		z-index: 12;
		&.type-a {
			background-color: #6b32fa;
			color: white;
		}
		&.type-b {
			background-color: #ffffff;
			color: #6b32fa;
			border: none;
		}
	}
	.slick-slide {
		height: auto;
	}
	.slick-dots {
		bottom: -40px;
	}
	.slick-dots li {
		margin: 0;
	}
}

@media only screen and (min-width: 991px) {
	.help-modal {
		width: 590px;
		height: 540px;
		top: calc(50% - 260px);
		left: calc(50% - 295px);
		background-color: white;
		padding: 0px;
		.mobile-header {
			display: none;
		}
		.desktop-header {
			display: block;
			width: 20px;
			height: 20px;
			background-image: url("../../common/images/help-section-close.svg");
			position: absolute;
			right: 20px;
			top: 20px;
			color: #8a8a8f;
			font-size: 20px;
			cursor: pointer;
			z-index: 10;
		}
		.help-content {
			padding-left: 60px;
			padding-right: 60px;
		}
		.wrapper {
			display: inline-block !important;
			height: 100%;
		}
		.slick-slide {
			padding: 50px;
			background-color: white;
		}
		.slick-dots {
			bottom: -5px;
		}
		.slick-slide {
			max-width: 590px;
		}
		.next {
			right: 165px;
			bottom: 30px;
		}
		.previous {
			left: 165px;
			bottom: 30px;
		}
		.button {
			max-width: 120px;
			font-size: 14px;
			font-family: Montserrat;
			height: 40px;
			border-radius: 0px;
			display: inline-block;
			line-height: 40px;
			text-align: center;
			margin-right: 0px;
			cursor: pointer;
			z-index: 12;
			&.type-a {
				background-color: #6b32fa;
				color: white;
			}
			&.type-b {
				background-color: #ffffff;
				color: #6b32fa;
				border: none;
			}
		}
        .basic-content {
            padding: 80px;
        }
	}
}
