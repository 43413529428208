$max-width: 480px;
.list-wrap {
	height: calc(100vh - 30px); //fall back
	height: calc(
		var(--vh, 1vh) * 100 - 30px
	); //safari in mobile view port height fix
	overflow: scroll;
	// padding: 20px;
	z-index: 9;
	-webkit-transition: top 500ms ease;
	-o-transition: top 500ms ease;
	transition: top 500ms ease;
	width: 100%;
	position: relative;
	top: 30px;
	padding-top: 0;
	-webkit-overflow-scrolling: touch;
	touch-action: pan-y;
	background: white;
	border-radius: 8px;
	&.searching {
		height: calc(100vh - 100px); //fall back
		height: calc(
			var(--vh, 1vh) * 100 - 100px
		); //safari in mobile view port height fix
		z-index: 9;
		top: 100px;
	}
	&.down {
		top: calc(100vh - 210px); //fall back
		overflow: hidden;
		padding: 0;
		top: calc(
			var(--vh, 1vh) * 100 - 210px
		); //safari in mobile view port height fix
		.printers-list-section {
			position: fixed;
			background: white;
			box-shadow: none;
		}
	}
	.swipe-section-wrap {
		padding: 0px 20px 0px 20px;
		left: 0px;
		z-index: 10;
		text-align: center;
		position: fixed;
		width: 100%;
		background: white;
		.swipe-section {
			background: white;
			height: 66px;
			position: relative;
			.swipe-image {
				position: absolute;
				left: 50%;
				transform: translate(-50%, 0%);
				height: 65px;
				width: 70px;
				margin-top: 5px;
				display: inline-block;
			}
			.swipe-up {
				width: 40px;
				height: 32px;
				transform: rotate(180deg);
				background-repeat: no-repeat;
				cursor: pointer;
				display: inline-block;
			}
			.swipe-down {
				width: 40px;
				margin-top: 10px;
				height: 32px;
				background-repeat: no-repeat;
				display: inline-block;
				cursor: pointer;
			}
		}
	}
	.printers-list-section {
		padding: 20px;
		padding-top: 75px;
		padding-bottom: 0px;
		background: white;
		.list-section {
			min-height: 140px;
			cursor: pointer;
			background: #ffffff;
			padding-top: 0px;
			padding-bottom: 0px;
		}
	}
	.printers-list-section {
		width: 100%;
	}
	.no-printers-found {
		padding-bottom: 20px;
	}
}
.filter-content {
	display: none;
}
.filter-icon {
	height: 22px;
	width: 22px;
	background-size: contain;
	position: absolute;
	top: 5px;
	right: 5px;
	background-repeat: no-repeat;
}

@media only screen and (min-width: 991px) {
	.list-wrap {
		top: 0;
		max-width: $max-width;
		padding: 0px;
		left: 0px;
		&.searching {
			top: unset;
		}
		&.down {
			top: unset;
		}
		.swipe-section-wrap {
			display: none;
		}
		.printers-list-section {
			padding: 20px 20px 100px 30px;
			.list-section {
				min-height: 140px;
				padding-bottom: 20px;
				padding-top: 15px;
			}
		}
	}
	.filter-content {
		height: 0px;
		max-height: 0px;
		background: #efe8ff;
		transition: max-height 0.15s;
		max-width: $max-width;
		overflow: auto;
		display: block;
		&.open {
			height: 200px;
			max-height: 270px;
		}
	}
	.search-content {
		background: white;
		transition: max-height 0.15s;
		overflow: auto;
		text-align: center;
		flex: 100%;
	}
}
